const css = `.splash-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.splash-animation-container {
  height: 50vh;
}

.splash-title-container {
  font-size: 2.5rem;
  word-spacing: 10px;
  text-decoration: none;
}

.splash-title {
  font-family: "Agustina Regular", cursive;
  font-weight: bold;
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .splash-title-container {
    font-size: 1.5rem;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RpeGl0cmphaW4vcHJvamVjdHMvZGV2cG9ydGZvbGlvL2FwcHMvcG9ydGZvbGlvLXVpL3NyYy9jb250YWluZXJzL3NwbGFzaFNjcmVlbiIsInNvdXJjZXMiOlsiU3BsYXNoU2NyZWVuLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0lBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuc3BsYXNoLWNvbnRhaW5lciB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBtaW4taGVpZ2h0OiAxMDB2aDtcbn1cblxuLnNwbGFzaC1hbmltYXRpb24tY29udGFpbmVyIHtcbiAgaGVpZ2h0OiA1MHZoO1xufVxuXG4uc3BsYXNoLXRpdGxlLWNvbnRhaW5lciB7XG4gIGZvbnQtc2l6ZTogMi41cmVtO1xuICB3b3JkLXNwYWNpbmc6IDEwcHg7XG4gIHRleHQtZGVjb3JhdGlvbjogbm9uZTtcbn1cblxuLnNwbGFzaC10aXRsZSB7XG4gIGZvbnQtZmFtaWx5OiBcIkFndXN0aW5hIFJlZ3VsYXJcIiwgY3Vyc2l2ZTtcbiAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gIGZvbnQtdmFyaWFudC1saWdhdHVyZXM6IG5vLWNvbW1vbi1saWdhdHVyZXM7XG4gIC13ZWJraXQtZm9udC12YXJpYW50LWxpZ2F0dXJlczogbm8tY29tbW9uLWxpZ2F0dXJlcztcbiAgcGFkZGluZzogMCAyMHB4O1xufVxuXG5AbWVkaWEgKG1heC13aWR0aDogNzY4cHgpIHtcbiAgLnNwbGFzaC10aXRsZS1jb250YWluZXIge1xuICAgIGZvbnQtc2l6ZTogMS41cmVtO1xuICB9XG59XG5cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
